import { useCallback, useState, useEffect } from 'react'

export const relativeDateString = (d) => {
  // console.log("formatting date", d)
  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  const ordinals = ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"]
  const oneDay = (1000 * 60 * 60 * 24)

  const date = new Date(d)
  const now = new Date()
  const diff = now - date
  let when = ""

  switch (true) {
    case (diff < oneDay):
      when = "Today"
      break
    case (diff < (oneDay * 2)):
      when = "Yesterday"
      break
    case (diff < (oneDay * 7)):
      when = `${dayNames[date.getDay()]}`
      break
    case (diff < (oneDay * 14)):
      when = `Last ${dayNames[date.getDay()]}`
      break
    default:
      when = date.toString().substr(0, 8) + (date.getDate()) + ordinals[date.getDate() % 10]
  }
  return when

}

function useEventListener(element, type, listener) {
  useEffect(() => {
    if (!element) {
      return undefined
    }

    element.addEventListener(type, listener)

    return () => {
      element.removeEventListener(type, listener)
    }
  }, [element, type, listener])
}

/**
 * Returns the interior width of the window in pixels.
 *
 * @returns {number} Width of the window in pixels
 */
export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const getWindowWidth = useCallback(
    () => setWindowWidth(window.innerWidth),
    [],
  )

  useEventListener(window, 'resize', getWindowWidth)

  return windowWidth
}