import { useEffect, useState } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faBolt, faCloudMoon, faAngleDoubleRight, faAngleDoubleLeft, faChartLine } from '@fortawesome/free-solid-svg-icons'

import { ComposedChart, Area, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, Scatter } from 'recharts'

import Slider from 'rc-slider'
import Range from 'rc-slider'
import 'rc-slider/assets/index.css'

import { relativeDateString, useWindowWidth } from './utils/utils'

function App() {

  const VERSION = process.env.REACT_APP_VERSION

  const [data, setData] = useState({})
  const [todayData, setTodayData] = useState({})
  const [monthData, setMonthData] = useState({})
  const [todayDataTable, setTodayDataTable] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [day, setDay] = useState("Today")
  const [daysAvailable, setDaysAvailable] = useState([])
  const [daysIndex, setDayIndex] = useState(0)
  const [peakPower, setPeakPower] = useState(0)
  const [scale1, setScale1] = useState([0, 2400])
  const [scale2, setScale2] = useState([0, 17])
  const [scaleUp, setScaleUp] = useState(true)

  const colourArray = ["rgba(128,128,94,0.6)", "rgba(227,227,129,0.6)", "rgba(240,235,45,0.6)", "rgba(240,175,35,0.6)", "rgba(250,90,25,0.6)", "rgba(255,45,25,0.6)"]
  const wattageLabels = { 2450: "Night Time", 2300: "Barely Registering", 2000: "Meh", 1600: "Decent", 1200: "Getting Better", 800: "Scorchio", 400: "Take Cover", 0: "Apocalypse!" }

  const screenWidth = useWindowWidth()
  console.log("screenWidth", screenWidth)

  const sleep = async (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  const changeDay = (delta) => {
    let newDay = daysIndex + delta
    if (newDay < 0) {
      return
    }
    if (newDay >= daysAvailable.length) {
      return
    }
    if (delta === 0) {
      newDay = 0
      setDay("Today")
    }
    else {
      switch (newDay) {
        case 0:
          setDay("Today")
          break
        case 1:
          setDay("Yesterday")
          break
        default:
          setDay(relativeDateString(daysAvailable[newDay]))
      }
    }
    setDayIndex(newDay)
    let tempData = JSON.parse(JSON.stringify(data.dayData.filter(r => r.TimeStamp.substr(0, 10) === daysAvailable[newDay]).reverse()))
    let t = 0
    tempData.forEach((r, i) => { t += r.power; tempData[i].energy = t / 12000; tempData[i].TimeStamp = tempData[i].TimeStamp.substr(11, 8) })
    setTodayDataTable(tempData)
    const maxPower = tempData.reduce(function (prev, current) { return (prev.power > current.power) ? prev : current })
    setPeakPower(maxPower)
  }

  const pickDay = (newDate) => {
    const newDayIndex = Math.floor((new Date() - new Date(newDate)) / 1000 / 60 / 60 / 24)
    setDayIndex(newDayIndex)
    if (newDayIndex === 0) {
      setDay("Today")
    }
    else {
      switch (newDayIndex) {
        case 0:
          setDay("Today")
          break
        case 1:
          setDay("Yesterday")
          break
        default:
          setDay(relativeDateString(daysAvailable[newDayIndex]))
      }
    }
    let tempData = JSON.parse(JSON.stringify(data.dayData.filter(r => r.TimeStamp.substr(0, 10) === daysAvailable[newDayIndex]).reverse()))
    let t = 0
    tempData.forEach((r, i) => { t += r.power; tempData[i].energy = t / 12000; tempData[i].TimeStamp = tempData[i].TimeStamp.substr(11, 8) })
    setTodayDataTable(tempData)
    const maxPower = tempData.reduce(function (prev, current) { return (prev.power > current.power) ? prev : current })
    setPeakPower(maxPower)
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (data.dayData) {
      console.log("data", data)
      const latestDate = data.dayData[0].TimeStamp.substr(0, 10)
      const latestTime = data.dayData[0].TimeStamp.substr(11, 8)
      const latestPower = data.dayData[0].power
      let tempData = JSON.parse(JSON.stringify(data.dayData.filter(r => r.TimeStamp.substr(0, 10) === latestDate).reverse()))
      let t = 0
      tempData.forEach((r, i) => { t += r.power; tempData[i].energy = t / 12000; tempData[i].TimeStamp = tempData[i].TimeStamp.substr(11, 8) })
      setTodayDataTable(tempData)
      const latestEnergy = tempData[tempData.length - 1].energy
      const maxPower = tempData.reduce(function (prev, current) { return (prev.power > current.power) ? prev : current })
      setPeakPower(maxPower)
      const mData = data.monthData.slice(-31)
      const maxDayinMonth = mData.reduce(function (prev, current) { return (prev.energy > current.energy) ? prev : current })
      const maxDayIndex = mData.findIndex(d => d.TimeStamp === maxDayinMonth.TimeStamp)
      const peakPeak = data.dayData.reduce(function (prev, current) { return (prev.power > current.power) ? prev : current })
      const dayPeaks = data.dayData.reduce((a, v) => (v.power < (a[v.TimeStamp.substr(0, 10)] || {}).power || (a[v.TimeStamp.substr(0, 10)] = v), a), {})
      const newMData = data.monthData.slice(-31).map(d => ({ ...d, "peak": dayPeaks[d.TimeStamp]?.power }))
      setTodayData({ "day": latestDate, "time": latestTime, "power": latestPower, "energy": latestEnergy, "peakTime": maxPower.TimeStamp.substr(0, 5), "peakPower": maxPower.power, "bestDay": maxDayinMonth.TimeStamp, "bestDayEnergy": maxDayinMonth.energy, "bestDayIndex": maxDayIndex, "peakPeakPower": data.maxData[0].power, "peakPeakDate": relativeDateString(data.maxData[0].TimeStamp.substr(0, 10)) })
      setMonthData(newMData)
      const daysInTheArray = [...new Set(data.dayData.map(r => r.TimeStamp.substr(0, 10)))].sort().reverse()
      setDaysAvailable(daysInTheArray)
      switch (daysIndex) {
        case 0:
          setDay("Today")
          break
        case 1:
          setDay("Yesterday")
          break
        default:
          setDay(daysInTheArray[daysIndex])
      }
      setDayIndex(0)
    }
  }, [data])

  const getData = () => {
    setLoading(true)
    axios("/api/solardata")
      .then(response => {
        setData(response.data)
      })
      .catch(error => {
        console.error("Error fetching data:", error)
        setError(error)
      })
      .finally(() => {
        setLoading(false)
        setError(null)
      })
    setDayIndex(0)
  }

  const setScaleRange = () => {
    if (scaleUp) {
      setScale1([0, 'auto'])
      setScale2([0, 'auto'])
      setScaleUp(false)
    }
    else {
      setScale1([0, 2300])
      setScale2([0, 17])
      setScaleUp(true)
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1><FontAwesomeIcon icon={faSun} style={{ color: "yellow", marginRight: "1rem" }} onClick={getData} />Latest Solar Generation Data{loading && <><FontAwesomeIcon icon={faBolt} style={{ color: "yellow", margin: "0 1rem" }} />Loading...</>}</h1>
      </header>
      <article>
        {error && <p className="error">Error... {error.message}</p>}
        <p>Latest data as at <span className="bold">{todayData.day}, {todayData.time}</span> - currently outputting <span className="bold">{todayData.power}</span> Watts, <span className="bold">{todayData.energy}</span> Kwh so far</p>
        <p>Peak power today: <span className="bold">{todayData.peakPower}</span> Watts achieved at <span className="bold">{todayData.peakTime}</span></p>
        <div className="day-container">
          <div className="day">{day}</div>
          <div className="scale-change"><FontAwesomeIcon icon={faChartLine} style={{ color: "orange" }} onClick={setScaleRange} title="Change scale" /></div>
          <ResponsiveContainer width="90%" height="80%" minWidth={400} minHeight={400}>
            <ComposedChart
              width={1500}
              height={800}
              data={todayDataTable}
              margin={{
                top: 5,
                right: 10,
                left: 10,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="TimeStamp" padding={{ left: 30, right: 30 }} stroke="yellow" />
              <YAxis yAxisId="left" orientation="left" label={{ value: "Energy Generation kWh", angle: 270, position: "left", fill: "orange" }} stroke="orange" domain={scale2} />
              <YAxis yAxisId="right" orientation="right" label={{ value: "Power Out W", angle: 90, position: "right", fill: "yellow" }} stroke="yellow" domain={scale1} />
              <Tooltip labelStyle={{ color: "#883300" }} contentStyle={{ color: "#883300" }} />
              <Legend />
              <Area type="monotone" dataKey="energy" yAxisId="left" fill="hsl(23deg 50% 50%)" stroke="hsl(23deg 80% 50%)" />
              <Line type="monotone" dataKey="power" yAxisId="right" dot={false} stroke="hsl(53deg 100% 50%)" strokeWidth={3} />
            </ComposedChart>
          </ResponsiveContainer>
        </div>

        <Slider id="dayspicker" min={0} max={screenWidth < 800 ? 8 : 30} value={daysIndex} onChange={(v) => { changeDay(v - daysIndex) }}
          reverse={true}
          marks={screenWidth < 800 ? daysAvailable.slice(0, 8).map(d => relativeDateString(d)) : daysAvailable.slice(0, 30).map(d => relativeDateString(d))}
          style={{ width: "90%", height: "5rem" }}
          railStyle={{ height: "1.2rem", background: "linear-gradient(90deg, rgba(50,50,50,1) 0%, rgba(224,224,147,1) 25%, rgba(241,241,3,1) 50%, rgba(243,175,0,1) 75%, rgba(255,46,0,1) 100%)" }}
          trackStyle={{ height: "1.2rem", backgroundColor: "hsl(2deg 79% 44%)" }}
          dotStyle={{ display: "none" }}
          handleStyle={{ height: "30px", width: "30px", backgroundColor: "hsl(0deg 100% 50%" }} />
        <h1>Current Weather Indicator</h1>
        <div className="weather">
          <FontAwesomeIcon icon={faCloudMoon} style={{ color: "gray" }} onClick={getData} />
          <Slider min={0} max={2450} value={(2450 - todayData.power)} reverse={true}
            style={{ width: "85%", height: "4rem" }}
            railStyle={{ height: "1.2rem", background: "linear-gradient(90deg, rgba(50,50,50,1) 0%, rgba(224,224,147,1) 25%, rgba(241,241,3,1) 50%, rgba(243,175,0,1) 75%, rgba(255,46,0,1) 100%)" }}
            trackStyle={{ height: "1.2rem", backgroundColor: "hsl(2deg 49% 14%)" }}
            dotStyle={{ display: "none" }}
            marks={wattageLabels}
            handleStyle={{ display: "none" }} />
          <FontAwesomeIcon icon={faSun} style={{ color: "yellow" }} onClick={getData} />
        </div>
        <h1><FontAwesomeIcon icon={faSun} style={{ color: "yellow", marginRight: "1rem" }} />Last 31 days Energy Generation</h1>
        <ResponsiveContainer width="90%" height="80%" minWidth={400} minHeight={400}>
          <ComposedChart width={1500} height={800} data={monthData} margin={{ top: 5, right: 30, left: 20, bottom: 5, }}>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip labelStyle={{ color: "#883300" }} />
            <XAxis dataKey="TimeStamp" label={{ fill: "yellow" }} stroke="yellow" />
            <YAxis dataKey="energy" yAxisId="left" orientation="left" label={{ value: "Daily Energy Generation kWh", angle: 270, position: "left", fill: "orange" }} stroke="orange" />
            <YAxis yAxisId="right" orientation="right" label={{ value: "Peak Power Out W", angle: 90, position: "right", fill: "yellow" }} stroke="yellow" />
            {/* <Bar dataKey="energy" name="Energy per Day" fill="hsl(53deg 100% 50% / 60%)" onAnimationEnd={() => document.querySelectorAll(".recharts-bar-rectangle path")[todayData.bestDayIndex].style.fill = "hsl(35deg 100% 50% / 90%)"} /> */}
            <Bar dataKey="energy" yAxisId="left" name="Energy per Day" fill="hsl(53deg 100% 50% / 60%)"
              onClick={(e) => { pickDay(e.TimeStamp) }}
              onAnimationEnd={() => document.querySelectorAll(".recharts-bar-rectangle path")[todayData.bestDayIndex].classList.add("pulse")}
            >
              {
                monthData.length > 10 && (
                  monthData.map((m, i) => {
                    let fillIndex = 5
                    switch (true) {
                      case (m.energy < 2):
                        fillIndex = 0
                        break
                      case (m.energy < 4):
                        fillIndex = 1
                        break
                      case (m.energy < 6):
                        fillIndex = 2
                        break
                      case (m.energy < 8):
                        fillIndex = 3
                        break
                      case (m.energy < 10):
                        fillIndex = 4
                        break
                      default:
                        fillIndex = 5
                    }
                    return (<Cell fill={colourArray[fillIndex]} />)
                  })
                )
              }
            </Bar>
            <Scatter dataKey="peak" yAxisId="right" fill="yellow" shape="star" />
          </ComposedChart>
        </ResponsiveContainer>
        <p>Best day in the last month for Solar Power Generation was <span className="bold">{todayData.bestDay}</span> when we generated <span className="bold">{todayData.bestDayEnergy}kWh</span></p>
        <p>Best ever power output Peak was achieved <span className="bold">{todayData?.peakPeakDate}</span> and was <span className="bold">{todayData.peakPeakPower}</span> Watts</p>
        <p className="bold">All data and code copyright &copy; 2021 Hamish Foxley</p>
        <p>App version {VERSION}. Screen width: {screenWidth}</p>
      </article>
    </div>
  )
}

export default App
